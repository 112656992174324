import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import CreateEndpoint from "./components/ModalCreateEndpoint";
import { useNotification } from "components/Notification/NotificationContext";
import EndpointActions from './components/EndpointActions';

const API_URL = process.env.REACT_APP_API_URL;

const ProjectDetails = () => {
  // const [endpoints, setEndpoints] = useState([{ _id: 'loading', path: 'Loading...' }]); // Estado inicial
  const [endpoints, setEndpoints] = useState([]);
  const [loading, setLoading] = useState(true); // Estado de carga
  const { projectId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { notification } = useNotification();
  const token = localStorage.getItem('jwt');

  const handleCreateEndpoint = () => {
    setIsModalOpen(true);
  };

  const refreshEndpoints = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/endpoints/${projectId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setEndpoints(response.data);
    } catch (error) {
      console.error('Error fetching endpoints data:', error);
    } finally {
      setLoading(false);
    }
  }, [projectId, token]);

  const handleSubmit = async (endpointData) => {
    try {
      await axios.post(`${API_URL}/endpoints`, endpointData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      refreshEndpoints();
    } catch (error) {
      console.error('Error creating endpoint:', error);
    }
  };

  const handleBackToProjects = () => {
    window.location.href = '/projects';
  };

  useEffect(() => {
    refreshEndpoints();
  }, [projectId, refreshEndpoints]);

  return (
    <div>
      <div className="mt-5 flex justify-between items-center relative z-10">
        <div className="flex items-center">
          <button
            className="text-zinc-200 mr-2"
            onClick={handleBackToProjects}
            title='Back to Projects'
          >
            Projects /
          </button>
          <h2 className="text-xl font-bold text-zinc-200">Endpoints</h2>
        </div>
        <button
          className="relative inline-flex shrink-0 font-medium text-zinc-200"
          onClick={handleCreateEndpoint}
        >
          <span className="z-10 inline-flex h-full w-full items-center justify-center rounded-md bg-lightPrimary px-3 py-1.5 ring-1 ring-zinc-400/10 transition hover:ring-zinc-400/40 max-md:hidden">
            New Endpoint
          </span>
          <span
            className="z-10 inline-flex h-full w-full items-center justify-center rounded-md bg-lightPrimary px-2 py-1.5 ring-1 ring-zinc-400/10 transition hover:ring-zinc-400/40 md:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg" width="18" height="18"
              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"
              className="lucide lucide-plus">
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
          </span>
          <span
            className="absolute h-full w-full blur-[1rem] motion-safe:animate-pulse"
            style={{ background: 'linear-gradient(90deg, rgba(255, 214, 0, 0.2) 0%, rgba(255, 46, 0, 0.3) 10%, rgba(219, 0, 255, 0.5) 25%, rgb(97, 0, 255) 45%, rgb(0, 255, 117) 65%, rgb(0, 255, 255) 80%, rgba(20, 0, 255, 0.3) 100%)' }}>
          </span>
        </button>
      </div>

      {notification && (
        <div className="bg-green-500 py-2 px-4 rounded-md text-white text-center fixed bottom-4 right-4 flex gap-4" role="alert">
          <span className="font-medium">{notification}</span>
        </div>
      )}
      <CreateEndpoint
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        refreshEndpoints={refreshEndpoints}
        handleSubmit={handleSubmit}
        projectId={projectId}
      />

      {/* Renderizar Endpoints */}
      {loading ? (
        <div className="mt-5 flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white"></div>
        </div>
      ) : endpoints.length === 0 ? (
        <div className="mt-5 flex justify-center items-center">
          <p className="text-lg font-bold text-white">No endpoints yet</p>
        </div>
      ) : (
        <>
        <div className="mt-5 grid grid-cols-1 gap-3">
          {endpoints.map((endpoint) => (
            <div
              key={endpoint._id}
              className="!z-5 relative flex flex-col rounded-[5px] bg-clip-border border border-dashed border-[#333333] shadow-md text-center no-underline transition duration-300 p-[0.0625rem] hover:border-solid hover:border-[#333333]"
            >
              <div class="relative p-[1rem] bg-black rounded-[5px] overflow-hidden xl:p-[1rem]">
                <div className="flex justify-between items-center">
                  <div className="flex h-full w-full flex-row justify-between items-center sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
                    <div className="flex flex-grow items-center">
                      <a
                      href={`/endpoints/${endpoint._id}`}
                      className="text-base font-bold text-white mr-4 no-underline"
                    >
                      /api/v1/{endpoint.path}
                    </a>
                      {/* <p className="text-base font-bold text-white">{endpoint.method}</p> */}
                    </div>

                    <EndpointActions
                      endpointId={endpoint._id}
                      onEdit={refreshEndpoints}
                      onView={refreshEndpoints}
                      onDelete={refreshEndpoints}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        </>
      )}
    </div>
  );
};

export default ProjectDetails;