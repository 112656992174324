import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import InputField from "components/fields/InputField";
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const API_URL = process.env.REACT_APP_API_URL;

export default function SignIn() {
  const navigate = useNavigate();
  const [isRegistering, setIsRegistering] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleGoogleSuccess = async (response) => {
    try {
      const { credential } = response;
      const decoded = jwtDecode(credential);
      const { email, name } = decoded;

      const res = await axios.post(`${API_URL}/auth/google`, { email, name });
      const { token } = res.data;

      localStorage.setItem('jwt', token);
      navigate('/projects');
    } catch (error) {
      console.error('Google Sign-In failed:', error);
    }
  };

  const handleGoogleFailure = (error) => {
    console.error('Google Sign-In failed:', error);
  };

  const validateForm = (email, password, name = '') => {
    const newErrors = {};
    if (!email) newErrors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = 'Email is invalid';
    if (!password) newErrors.password = 'Password is required';
    else if (isRegistering && password.length < 8) newErrors.password = 'Password must be at least 8 characters';
    if (isRegistering && !name) newErrors.name = 'Name is required';
    return newErrors;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    const newErrors = validateForm(email, password);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/auth/login`, { email, password });
      if (response.data.token) {
        localStorage.setItem('jwt', response.data.token);
        navigate('/projects');
      }
    } catch (error) {
      setErrors({ form: 'Login failed. Please try again.' });
    } finally {
      setLoading(false);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);

    const newErrors = validateForm(email, password, name);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/auth/register`, { email, password, name });
      if (response.status === 201) {
        setIsRegistering(false);
        if (response.data.token) {
          localStorage.setItem('jwt', response.data.token);
          navigate('/projects');
        } else {
          setErrors({ form: 'Registration successful. Please log in.' });
        }
      }
    } catch (error) {
      if (error.response) {
        setErrors({ form: error.response.data.message || 'Registration failed. Please try again.' });
      } else if (error.request) {
        setErrors({ form: 'Could not connect to the server. Please check your connection.' });
      } else {
        setErrors({ form: 'An unexpected error occurred. Please try again.' });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setName(newName);
    if (isRegistering) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: newName ? "" : "Name is required",
      }));
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setErrors((prevErrors) => ({
      ...prevErrors,
      email: newEmail
        ? /\S+@\S+\.\S+/.test(newEmail)
          ? ""
          : "Email is invalid"
        : "Email is required",
    }));
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setErrors((prevErrors) => ({
      ...prevErrors,
      password: newPassword
        ? isRegistering && newPassword.length < 8
          ? "Password must be at least 8 characters"
          : ""
        : "Password is required",
    }));
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  
  return (
    <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-white">
          {isRegistering ? 'Sign Up' : 'Sign In'}
        </h4>
        <p className="mb-2 ml-1 text-base text-zinc-400">
          {isRegistering ? 'Enter your details to sign up!' : 'Enter your email and password to sign in!'}
        </p>
        <form onSubmit={isRegistering ? handleRegister : handleLogin}>
          {isRegistering && (
            <InputField
              variant="auth"
              extra="mb-3"
              label="Name *"
              placeholder="Your Name"
              id="name"
              name="name"
              value={name}
              onChange={handleNameChange}
              error={errors.name}
            />
          )}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Email *"
            placeholder="mail@example.com"
            id="email"
            name="email"
            value={email}
            onChange={handleEmailChange}
            error={errors.email}
          />
          <div className="relative">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Password *"
              placeholder="********"
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              error={errors.password}
            />
            <div
              className="absolute right-3 top-[41px] transform translate-y-1/2 cursor-pointer text-white"
              onClick={toggleShowPassword}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>
          {errors.form && <p className="mb-2 text-sm text-red-500">{errors.form}</p>}
          <p className="mt-2 text-sm text-zinc-400">
            <span
              className="cursor-pointer text-white hover:underline"
              onClick={() => navigate("/auth/forgot-password")}
            >
              Forgot your password?
            </span>
          </p>
          <button type="submit" className="mb-2 mt-4 flex h-10 w-full items-center justify-center gap-2 rounded-xl bg-white text-sm font-semibold text-black hover:bg-zinc-300/90" disabled={loading}>
            {loading ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" viewBox="0 0 24 24">
                <circle cx="4" cy="12" r="3" fill="black">
                  <animate id="svgSpinners3DotsFade0" fill="freeze" attributeName="opacity" begin="0;svgSpinners3DotsFade1.end-0.25s" dur="0.75s" values="1;0.2"/>
                </circle>
                <circle cx="12" cy="12" r="3" fill="black" opacity="0.4">
                  <animate fill="freeze" attributeName="opacity" begin="svgSpinners3DotsFade0.begin+0.15s" dur="0.75s" values="1;0.2"/>
                </circle>
                <circle cx="20" cy="12" r="3" fill="black" opacity="0.3">
                  <animate id="svgSpinners3DotsFade1" fill="freeze" attributeName="opacity" begin="svgSpinners3DotsFade0.begin+0.3s" dur="0.75s" values="1;0.2"/>
                </circle>
              </svg>
            ) : (
              isRegistering ? 'Sign Up' : 'Sign In'
            )}
          </button>
        </form>
        <p className="mt-2 mb-4 text-base text-zinc-400">
          {isRegistering ? 'Already have an account?' : "Don't have an account?"}
          <span
            className="ml-1 text-white cursor-pointer"
            onClick={() => setIsRegistering(!isRegistering)}
          >
            {isRegistering ? 'Sign In' : 'Sign Up'}
          </span>
        </p>

        <div className="mb-6 flex items-center gap-3">
          <div className="h-px w-full bg-zinc-500" />
          <p className="text-base text-zinc-400"> or </p>
          <div className="h-px w-full bg-zinc-500" />
        </div>

        <div className="flex items-center justify-center">
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            onSuccess={handleGoogleSuccess}
            onError={handleGoogleFailure}
            useOneTap
            prompt="select_account"
          />
        </div>

        {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-black border border-[#333333] hover:cursor-pointer hover:bg-[#333333] hover:border-[#333333]"
          // onClick={handleGoogleSignIn}
        >
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-white">
            {isRegistering ? 'Sign Up with Google' : 'Sign In with Google'}
          </h5>
        </div> */}

      </div>
    </div>
  );
}
