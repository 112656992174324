import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import NotFound from "views/404/NotFound";

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  const isValidRoute = routes.some(route => location.pathname === `/${route.path}`);

  return (
    <div className="flex h-full w-full bg-black">
      {/* Navbar & Main Content */}
      <div className="h-full w-full md:w-3/5 mx-auto">
        {/* Main Content */}
        <main className={`mx-[12px] h-full flex-none transition-all`}>
          {/* Routes */}
          <div className="h-full">
            {isValidRoute ? (
              <>
                <Navbar
                  logoText={"QuickMockAPI"}
                  brandText={"Projects"}
                  {...rest}
                />
                <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2">
                  <Routes>
                    {getRoutes(routes)}
                    <Route path="/" element={<Navigate to="/projects" replace />} />
                  </Routes>
                </div>
                <div className="p-3">
                  <Footer />
                </div>
              </>
            ) : (
              <NotFound />
            )}
          </div>
        </main>
      </div>
    </div>
  );
}