import Home from "views/home";

import { Routes, Route } from "react-router-dom";

const HomeLayout = () => {
  return (
    <Routes>
      <Route index element={<Home />} />
      {/* Otras rutas internas de HomeLayout si las hay */}
    </Routes>
  );
};

// Add this line at the end of your file to fix the default export issue
export default HomeLayout;