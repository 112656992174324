import React from "react";
import { Routes, Route } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';

import HomeLayout from "layouts/home";
import AdminLayout from "layouts/project";
import AuthLayout from "layouts/auth";
import EndpointLayout from "layouts/endpoint";

import { NotificationProvider } from "components/Notification/NotificationContext";

const App = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <NotificationProvider>
        <Routes>
          <Route path="/" element={<HomeLayout />} />
          <Route path="auth/*" element={<AuthLayout />} />
          <Route path="/projects/:projectId" element={<EndpointLayout />} />
          <Route path="/*" element={<AdminLayout />} />
        </Routes>
      </NotificationProvider>
    </GoogleOAuthProvider>
  );
};

export default App;