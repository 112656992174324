import React from "react";
import Home from "views/home";
import MainDashboard from "views/project";
import ProjectDetails from "views/endpoint";
import Profile from "views/profile";

// Auth Imports
import SignIn from "views/auth/SignIn";
import ForgotPassword from "views/auth/ForgotPassword";
import UpdatePassword from "views/auth/UpdatePassword";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdPerson,
  MdLock,
} from "react-icons/md";

import ProtectedRoute from "components/ProtectedRoute";

const routes = [
  {
    name: "Home",
    layout: "/",
    path: "",
    component: <Home />,
  },
  {
    name: "Projects",
    layout: "/",
    path: "projects",
    icon: <MdHome className="h-6 w-6" />,
    component: (
      <ProtectedRoute>
        <MainDashboard />
      </ProtectedRoute>
    )
  },
  {
    name: "Endpoints",
    layout: "/",
    path: "endpoints",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: (
      <ProtectedRoute>
        <ProjectDetails />
      </ProtectedRoute>
    ),
    secondary: true,
  },
  {
    name: "Profile",
    layout: "/",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    )
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "forgot-password",
    icon: <MdLock className="h-6 w-6" />,
    component: <ForgotPassword />,
  },
  {
    name: "Update Password",
    layout: "/auth",
    path: "update-password",
    icon: <MdLock className="h-6 w-6" />,
    component: <UpdatePassword />,
  }
];

export default routes;
