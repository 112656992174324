import React, { useEffect, useState } from "react";
import Dropdown from "components/dropdown";
import avatar from "assets/img/avatars/avatar.jpg";
import axios from "axios";
import logoWhite from "assets/img/layout/logo-white.svg";
import { googleLogout } from "@react-oauth/google";

axios.defaults.withCredentials = true;

const API_URL = process.env.REACT_APP_API_URL;

const Navbar = (props) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Obtener datos del usuario
    const token = localStorage.getItem('jwt');
    
    axios.get(`${API_URL}/dashboard`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        setUser(response.data);
      })
      .catch(error => {
        console.error('Error fetching dashboard data:', error);
      });
  }, []);

  const handleLogout = () => {
    const token = localStorage.getItem('jwt');

    axios.get(`${API_URL}/auth/logout`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      withCredentials: true
    })
      .then(response => {
        localStorage.removeItem('jwt');
        googleLogout(); // Cierra la sesión de Google
        window.location.href = '/';
      })
      .catch(error => {
        console.error('Error logging out:', error);
      });
  };

  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl py-2 backdrop-blur-xl">
      <div className="ml-[6px]">
        <div className="text-white">
          {/* <span className="text-sm md:text-base xl:text-lg">QuickMockAPI</span> */}
          <img src={logoWhite} alt="LogoQuickMockAPI" className="h-8 md:h-10 xl:h-12" />
        </div>
      </div>

      <div className="relative flex h-[61px] flex-grow items-center justify-end gap-3 px-2 py-2 md:flex-grow-0 md:gap-1 xl:gap-3">
        <div className="flex h-full items-center rounded-full text-white xl:w-[225px]"></div>
        <span className="flex cursor-pointer">
          <span className="text-xs md:text-sm xl:text-base text-white cursor-pointer hover:text-gray-300"> Docs </span>
        </span>
        
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <img
              className="h-10 w-10 rounded-full"
              src={avatar}
              alt={user && user.name ? user.name : "Avatar"}
            />
          }
          children={
            <div className="flex w-56 flex-col justify-start rounded-[10px] bg-lightPrimary bg-no-repeat shadow-xl border-2 border-white/10 shadow-shadow-500">
              <div className="p-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-white">
                    👋 Hey, {user ? user.name : "Guest"}

                  </p>{" "}
                </div>
              </div>
              <div className="h-px w-full bg-white/10" />

              <div className="flex flex-col p-4">
                <a
                  href=" "
                  className="text-sm text-gray-400 hover:text-white"
                >
                  Profile Settings
                </a>
                <div className="relative flex h-[61px] flex-grow items-center justify-end gap-3 px-2 py-2 md:flex-grow-0 md:gap-1 xl:gap-3">
                  {user && (
                    <button onClick={handleLogout} className="text-white">
                      Logout
                    </button>
                  )}
                </div>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[176px] w-max"}
        />
      </div>
    </nav>
  );
};

export default Navbar;
