import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import InputField from "components/fields/InputField";

const API_URL = process.env.REACT_APP_API_URL;

export default function UpdatePassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState(["", "", "", "", "", ""]);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const email = location.state?.email;

  const handleCodeChange = (index, value) => {
    if (value.length > 1) {
      const newCode = [...verificationCode];
      for (let i = 0; i < 6 && i < value.length; i++) {
        newCode[index + i] = value[i];
      }
      setVerificationCode(newCode);
    } else {
      const newCode = [...verificationCode];
      newCode[index] = value;
      setVerificationCode(newCode);
      if (value && index < 5) {
        document.getElementById(`code-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && index > 0 && verificationCode[index] === '') {
      const newCode = [...verificationCode];
      newCode[index - 1] = '';
      setVerificationCode(newCode);
      document.getElementById(`code-${index - 1}`).focus();
    }
  };

  const validatePasswords = (pass, confirmPass) => {
    if (pass.length < 8) {
      return "The password must be at least 8 characters";
    } else if (confirmPass && pass !== confirmPass) {
      return "The passwords do not match";
    }
    return "";
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordError(validatePasswords(newPassword, confirmPassword));
  };
  
  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    setPasswordError(validatePasswords(password, newConfirmPassword));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const code = verificationCode.join("");

    if (code.length !== 6) {
      setError("Please enter the verification code");
      setLoading(false);
      return;
    }

    if (!password || !confirmPassword) {
      setError("Please enter your password and confirm it");
      setLoading(false);
      return;
    }

    if (password.length < 8) {
      setError("Password must be at least 8 characters");
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      setLoading(false);
      return;
    }

    try {
      await axios.post(`${API_URL}/auth/reset-password`, { email, code, password });
      navigate("/auth/sign-in");
    } catch (error) {
      setError("Invalid or expired code");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <div className="mb-8 flex items-center justify-center">
          <div className="h-16 w-16 rounded-full bg-green-500 flex items-center justify-center">
            <svg className="h-8 w-8 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
          </div>
        </div>
        <h4 className="mb-2.5 text-4xl font-bold text-white text-center">
          Request sent successfully!
        </h4>
        <p className="mb-9 ml-1 text-base text-zinc-400 text-center">
            We've sent a 6-digit confirmation code to your inbox. Please enter the code in the box below to verify your email.
        </p>
        <form onSubmit={handleSubmit}>
          <InputField
            variant="auth"
            extra="mb-3"
            label="Email"
            placeholder={email}
            id="email"
            type="email"
            disabled
          />
          <div className="mb-3">
            <div
              className="flex justify-between mt-2"
              onPaste={(e) => {
                e.preventDefault();
                const pastedData = e.clipboardData.getData('text');
                const newCode = [...verificationCode];
                for (let i = 0; i < 6 && i < pastedData.length; i++) {
                  newCode[i] = pastedData[i];
                }
                setVerificationCode(newCode);
              }}
            >
              {verificationCode.map((digit, index) => (
                <input
                    key={index}
                    id={`code-${index}`}
                    className="w-12 h-12 text-center text-2xl font-bold bg-zinc-900 border border-[#333333] rounded-md text-white"
                    type="text"
                    placeholder="-"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleCodeChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                />
              ))}
            </div>
          </div>
          <InputField
            variant="auth"
            extra="mb-3"
            label="Password"
            placeholder="********"
            id="password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            error={passwordError}
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Confirm new password"
            placeholder="********"
            id="confirmPassword"
            type="password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            error={passwordError}
          />
          {error && <p className="text-red-500 text-sm mb-3">{error}</p>}
          <button
            type="submit"
            className="mb-3 w-full rounded-xl bg-white py-3 text-base font-medium text-black transition duration-200 hover:bg-zinc-300/90 active:bg-zinc-300/90"
            disabled={loading}
          >
            {loading ? "Updating..." : "Update password"}
          </button>
        </form>
        <div className="mt-4 flex items-center justify-between">
          <span
            className="flex items-center text-sm font-medium text-zinc-400 hover:text-white cursor-pointer"
            onClick={() => navigate("/auth/sign-in")}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" viewBox="0 0 24 24" className="mr-1">
              <path fill="currentColor" d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64"></path>
            </svg>
            Return to sign in
          </span>
          <span className="text-sm font-medium text-green-500 hover:underline cursor-pointer">
            Resend code
          </span>
        </div>
      </div>
    </div>
  );
}