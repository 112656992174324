import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNotification } from "components/Notification/NotificationContext";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const API_URL = process.env.REACT_APP_API_URL;

const ModalViewDetails = ({ isOpen, onClose, endpointId }) => {
  const { showNotification } = useNotification();
  const [endpointDetails, setEndpointDetails] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('curl');
  const [apiKey, setApiKey] = useState(null);
  const [copySuccess, setCopySuccess] = useState('');
  const token = localStorage.getItem('jwt');

  const handleCopy = () => {
    setCopySuccess('¡Copiado!');
    setTimeout(() => {
      setCopySuccess('');
    }, 2000);
  };

  useEffect(() => {
    if (isOpen) {
      // Desactivar el scroll del cuerpo principal
      document.body.style.overflow = 'hidden';

      // Limpiar el efecto cuando el modal se cierra
      return () => {
        document.body.style.overflow = 'auto';
      };
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && endpointId) {
      const fetchEndpointDetails = async () => {
        try {
          const response = await axios.get(`${API_URL}/endpoint/${endpointId}`, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
          setEndpointDetails(response.data);
        } catch (error) {
          showNotification('Error fetching endpoint details', 'error');
        }
      };

      fetchEndpointDetails();
    }
  }, [isOpen, endpointId, showNotification, token]);

  useEffect(() => {
    if (endpointDetails) {
      const fetchApiKey = async (projectId) => {
        try {
          const response = await axios.get(`${API_URL}/project/${projectId}`, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
          });
          setApiKey(response.data.apiKey);
        } catch (error) {
          console.error('Error fetching project details:', error);
        }
      };

      fetchApiKey(endpointDetails.project);
    }
  }, [endpointDetails, showNotification, token]);

  if (!isOpen || !endpointDetails) return null;

  const codeExamples = {
    curl: `curl -X ${endpointDetails.method} ${API_URL}/api/v1/${endpointDetails.path} -H "x-api-key: ${apiKey}"`,
    python: `import requests\n\nurl = "${API_URL}/api/v1/${endpointDetails.path}"\nheaders = {"x-api-key": "${apiKey}"}\nresponse = requests.${endpointDetails.method.toLowerCase()}(url, headers=headers)\nprint(response.json())`,
    javascript: `fetch("${API_URL}/api/v1/${endpointDetails.path}", {\n  method: "${endpointDetails.method}",\n  headers: {\n    "x-api-key": "${apiKey}"\n  }\n}).then(response => response.json()).then(data => console.log(data));`
  };

  return (
    <>
      <div className="fixed inset-0 z-40 bg-opacity-50 backdrop-blur-sm"></div>
      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto max-h-full" onClick={onClose}>
        <div
          className="relative bg-black border border-[#333333] rounded-lg shadow-lg max-w-3xl w-full p-6 max-h-[90vh] overflow-y-auto modal-scrollbar"
          onClick={(e) => e.stopPropagation()}
        >
          <button
            className="absolute top-4 right-4 text-gray-200 hover:text-gray-200"
            onClick={onClose}
          >
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
          <h2 className="text-2xl font-bold text-gray-200 mb-4">Endpoint Details</h2>

          <div className="items-start text-left">

            <div className="mb-4">
              <label className="block text-gray-200 text-xl font-bold">Endpoint:</label>
              <span className="text-zinc-400">{API_URL}/api/v1/{endpointDetails.path}</span>
            </div>
            <div className="mb-4">
              <label className="block text-gray-200 text-xl font-bold">Method:</label>
              <span className="text-zinc-400">{endpointDetails.method}</span>
            </div>
            <div className="mb-4">
              <label className="block text-gray-200 text-xl font-bold">Fields:</label>
              <span className="text-zinc-400">{endpointDetails.fields.join(', ')}</span>
            </div>
            <div className="mb-4">
              <label className="block text-gray-200 text-xl font-bold">Return Rate:</label>
              <span className="text-zinc-400">{endpointDetails.dataCount}</span>
            </div>

            <div className="mb-4 pt-4">
              <label className="block text-gray-200 text-xl font-bold">Request Example:</label>
              <div className="flex space-x-4">
                <button
                  className={`px-3 py-2 rounded-md ${selectedLanguage === 'curl' ? 'bg-white text-black' : 'bg-black text-zinc-400'}`}
                  onClick={() => setSelectedLanguage('curl')}
                >
                  cURL
                </button>
                <button
                  className={`px-3 py-2 rounded-md ${selectedLanguage === 'python' ? 'bg-white text-black' : 'bg-black text-zinc-400'}`}
                  onClick={() => setSelectedLanguage('python')}
                >
                  Python
                </button>
                <button
                  className={`px-3 py-2 rounded-md ${selectedLanguage === 'javascript' ? 'bg-white text-black' : 'bg-black text-zinc-400'}`}
                  onClick={() => setSelectedLanguage('javascript')}
                >
                  JavaScript
                </button>
              </div>

              <div className="relative">
                <SyntaxHighlighter
                  language={selectedLanguage}
                  style={oneDark}
                  customStyle={{ fontSize: '0.8em' }}
                >
                  {codeExamples[selectedLanguage]}
                </SyntaxHighlighter>
                <CopyToClipboard text={codeExamples[selectedLanguage]} onCopy={handleCopy}>
                  <button className="absolute top-0 right-0 mt-2 mr-1 bg-white text-black px-1 py-1 rounded">
                    {copySuccess ? (
                      <span>{copySuccess}</span>
                    ) : (
                      <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="1.5"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-copy">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                        <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                      </svg>
                    )}
                  </button>
                </CopyToClipboard>
              </div>
            </div>

            <div className="mb-4 pt-4">
              <label className="block text-gray-200 text-xl font-bold">Schema:</label>
              <SyntaxHighlighter
                language='json'
                style={oneDark}
                customStyle={{ fontSize: '0.8em' }}
              >
                {JSON.stringify(endpointDetails.schema, null, 2)}
              </SyntaxHighlighter>
            </div>

          </div>
          
          <div className="flex justify-center space-x-4">
            <button
              className="px-16 py-2 bg-gray-200 text-gray-900 rounded-md hover:bg-gray-300 font-bold"
              onClick={() => console.log('Edit')}
            >
              Edit
            </button>
            <button
              className="px-16 py-2 bg-gray-200 text-red-500 rounded-md hover:bg-gray-300 font-bold"
              onClick={() => console.log('Delete')}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalViewDetails;