import React from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "components/navbar"; // Asegúrate de que la ruta de importación sea correcta
import Footer from "components/footer/Footer"; // Asegúrate de que la ruta de importación sea correcta
import ProjectDetails from "views/endpoint";
// import grid_c from "assets/img/layout/grid-c.webp";

const EndpointLayout = () => {
  // Aquí puedes definir el estado y los efectos necesarios, similar a como lo haces en el layout de projects

  return (
    <div className="flex h-full w-full bg-black">
      <div className="h-full w-full md:w-3/5 mx-auto">
        <main className={`mx-[12px] h-full flex-none transition-all`}>
          <div className="h-full">
            {/* Renderiza el Navbar aquí */}
            <Navbar
              logoText={"QuickMockAPI"}
              brandText={"Endpoints"} // Puedes ajustar este texto según necesites
              // Aquí puedes pasar props adicionales al Navbar si es necesario
            />
            <div className="pt-5 mx-auto mb-auto h-full min-h-[84vh] p-2">
              <Routes>
                <Route index element={<ProjectDetails />} />
                {/* Aquí puedes añadir más rutas si es necesario */}
              </Routes>
            </div>
            <div className="p-3">
              {/* Renderiza el Footer aquí */}
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default EndpointLayout;