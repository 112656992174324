import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import InputField from "components/fields/InputField";

const API_URL = process.env.REACT_APP_API_URL;

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const validateForm = (email) => {
    if (!email) return "Email is required";
    if (!/\S+@\S+\.\S+/.test(email)) return "Email is not valid";
    return "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const email = e.target.email.value;

    const validationError = validateForm(email);
    if (validationError) {
      setError(validationError);
      setLoading(false);
      return;
    }

    try {
      await axios.post(`${API_URL}/auth/request-password-reset`, { email });
      navigate("/auth/update-password", { state: { email } });
    } catch (error) {
      setError("Error sending the request. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-white">
          Forgot your password?
        </h4>
        <p className="mb-9 ml-1 text-base text-zinc-400">
          Enter your email address and we will send you a instructions to reset your password.
        </p>
        <form onSubmit={handleSubmit}>
        <InputField
            variant="auth"
            extra="mb-3"
            label="Email *"
            placeholder="mail@example.com"
            id="email"
            type="email"
        />
        {error && <p className="text-red-500 text-sm mb-3">{error}</p>}
        <button
            type="submit"
            className="mb-3 w-full rounded-xl bg-white py-3 text-base font-medium text-black transition duration-200 hover:bg-zinc-300/90 active:bg-zinc-300/90"
            disabled={loading}
        >
            {loading ? "Sending..." : "Send request"}
        </button>
        </form>
        <div className="mt-4">
          <span
            className="flex items-center text-sm font-medium text-zinc-400 hover:text-white cursor-pointer"
            onClick={() => navigate("/auth/sign-in")}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" viewBox="0 0 24 24" className="mr-1">
              <path fill="currentColor" d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64"></path>
            </svg>
            Back to sign in
          </span>
        </div>
      </div>
    </div>
  );
}