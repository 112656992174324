import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNotification } from 'components/Notification/NotificationContext';

const API_URL = process.env.REACT_APP_API_URL;

const CreateEndpoint = ({ isOpen, onClose, refreshEndpoints, projectId }) => {
  const { showNotification } = useNotification();
  const [endpointPath, setEndpointPath] = useState('');
  const [method, setMethod] = useState('GET');
  const [fields, setFields] = useState('');
  const [dataCount, setDataCount] = useState(1);
  const [errors, setErrors] = useState({ endpointPath: 'Endpoint name cannot be empty' });
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  const resetForm = () => {
    setEndpointPath('');
    setMethod('GET');
    setFields('');
    setDataCount(1);
    setErrors({});
    setErrorMessage('');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newErrors = { ...errors };

    if (name === 'endpointPath') {
      setEndpointPath(value);
      if (value.trim() === '') {
        newErrors.endpointPath = 'Endpoint name cannot be empty';
      } else if (value.length > 50) {
        newErrors.endpointPath = 'Endpoint name cannot exceed 50 characters';
      } else {
        delete newErrors.endpointPath;
      }
    }

    if (name === 'method') setMethod(value);

    if (name === 'fields') {
      setFields(value);
      if (value.trim() === '') {
        newErrors.fields = 'Fields cannot be empty';
      } else if (value.length > 256) {
        newErrors.fields = 'Fields cannot exceed 256 characters';
      } else {
        delete newErrors.fields;
      }
    }

    if (name === 'dataCount') {
      if (/^\d*$/.test(value) && (value === '' || (parseInt(value) >= 1 && parseInt(value) <= 50))) {
        setDataCount(value);
        if (value.trim() === '' || parseInt(value) < 1 || parseInt(value) > 50) {
          newErrors.dataCount = 'Return rate must be a number between 1 and 50.';
        } else {
          delete newErrors.dataCount;
        }
      }
    }

    setErrors(newErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const newErrors = {};

    if (endpointPath.trim() === '') {
      newErrors.endpointPath = 'Endpoint name cannot be empty';
    }

    if (fields.trim() === '') {
      newErrors.fields = 'Fields cannot be empty';
    }

    const dataCountStr = String(dataCount).trim();
    if (dataCountStr === '' || parseInt(dataCountStr) < 1 || parseInt(dataCountStr) > 50) {
      newErrors.dataCount = 'Return rate must be a number between 1 and 50.';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }
    setErrors({});

    const token = localStorage.getItem('jwt');

    try {
      await axios.post(`${API_URL}/endpoint/create`, {
        path: endpointPath,
        method,
        projectId,
        fields: fields.split(',').map(field => field.trim()).filter(field => field !== ''),
        dataCount: parseInt(dataCountStr),
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      showNotification('Endpoint created successfully');
      await refreshEndpoints();
      resetForm();
      onClose();
    } catch (error) {
      setErrorMessage('Error creating endpoint. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div className="fixed inset-0 z-40 bg-opacity-50 backdrop-blur-sm" onClick={onClose}></div>
      <div role="dialog" id="radix-:R19la:" aria-describedby="radix-:R19laH2:" aria-labelledby="radix-:R19laH1:" data-state="open" className="fixed left-0 right-0 top-4 z-50 mx-auto w-full max-w-md rounded-[5px] border border-[#333333] bg-black pt-0 data-[state=closed]:animate-close-scale-out-fade data-[state=open]:animate-open-scale-in-fade sm:top-[calc(100vh-95%)]" tabIndex="-1" style={{ pointerEvents: 'auto' }}>
        <div className="relative pb-4 pt-6">
          <div className="mx-auto mt-4 flex h-20 w-20 animate-slide-fade-in items-center justify-center rounded-full bg-zinc-900/30 shadow-lg shadow-purple ring-1 ring-white/10">
            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round" className="text-zinc-400">
              <path d="M4 4h5l2 3h9a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2z"></path>
              <line x1="12" y1="11" x2="12" y2="17"></line>
              <line x1="9" y1="14" x2="15" y2="14"></line>
            </svg>
          </div>
        </div>
        <div className="relative z-10 mx-auto mt-4 w-full max-w-sm px-4 pb-8 animate-slide-fade-in">
          <div className="mb-6 text-center">
            <h1 className="mb-2 text-2xl font-bold text-zinc-200">Create New Endpoint</h1>
          </div>
          {errorMessage && <p className="mb-2 text-sm text-red-500">{errorMessage}</p>}
          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <div className="space-y-1">
                <label className="text-sm leading-none select-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-zinc-400" htmlFor="endpointPath">Endpoint name</label>
                <input
                  type="text"
                  id="endpointPath"
                  name="endpointPath"
                  value={endpointPath}
                  onChange={handleChange}
                  placeholder="Example: users"
                  className="relative w-full select-none appearance-none rounded-[5px] border border-white/10 bg-zinc-900 px-4 py-2 text-sm font-medium text-zinc-300 transition duration-200 ease-in-out placeholder:text-zinc-500 focus:outline-none focus:ring-1 focus:ring-zinc-600 focus:ring-offset-1 focus:ring-offset-zinc-900 disabled:cursor-not-allowed disabled:opacity-70"
                />
                {errors.endpointPath && <div className="text-red-500 text-sm">{errors.endpointPath}</div>}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="space-y-1">
                <label className="text-sm leading-none select-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-zinc-400" htmlFor="method">Method</label>
                <select
                  id="method"
                  name="method"
                  value={method}
                  onChange={handleChange}
                  className="relative w-full select-none appearance-none rounded-[5px] border border-white/10 bg-zinc-900 px-4 py-2 text-sm font-medium text-zinc-300 transition duration-200 ease-in-out focus:outline-none focus:ring-1 focus:ring-zinc-600 focus:ring-offset-1 focus:ring-offset-zinc-900 disabled:cursor-not-allowed disabled:opacity-70"
                >
                  <option value="GET">GET</option>
                  <option value="POST" disabled>POST</option>
                  <option value="PUT" disabled>PUT</option>
                  <option value="DELETE" disabled>DELETE</option>
                  <option value="PATCH" disabled>PATCH</option>
                </select>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="space-y-1">
                <label className="text-sm leading-none select-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-zinc-400" htmlFor="fields">Fields (comma separated)</label>
                <input
                  type="text"
                  id="fields"
                  name="fields"
                  value={fields}
                  onChange={handleChange}
                  placeholder="Example: id,name,age"
                  className="relative w-full select-none appearance-none rounded-[5px] border border-white/10 bg-zinc-900 px-4 py-2 text-sm font-medium text-zinc-300 transition duration-200 ease-in-out placeholder:text-zinc-500 focus:outline-none focus:ring-1 focus:ring-zinc-600 focus:ring-offset-1 focus:ring-offset-zinc-900 disabled:cursor-not-allowed disabled:opacity-70"
                />
                {errors.fields && <div className="text-red-500 text-sm">{errors.fields}</div>}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="space-y-1">
                <label className="text-sm leading-none select-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-zinc-400" htmlFor="dataCount">Return rate</label>
                <input
                  type="text"
                  id="dataCount"
                  name="dataCount"
                  value={dataCount}
                  onChange={handleChange}
                  placeholder="Example: 10"
                  className="relative w-full select-none appearance-none rounded-[5px] border border-white/10 bg-zinc-900 px-4 py-2 text-sm font-medium text-zinc-300 transition duration-200 ease-in-out placeholder:text-zinc-500 focus:outline-none focus:ring-1 focus:ring-zinc-600 focus:ring-offset-1 focus:ring-offset-zinc-900 disabled:cursor-not-allowed disabled:opacity-70"
                />
                {errors.dataCount && <div className="text-red-500 text-sm">{errors.dataCount}</div>}
              </div>
            </div>
            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="relative mt-4 inline-flex h-10 w-full select-none items-center justify-center gap-1 rounded-md border bg-zinc-200 !px-0 pl-4 pr-4 text-sm font-semibold text-zinc-950 transition duration-200 ease-in-out hover:bg-zinc-300/90 focus:bg-zinc-300/90 focus:outline-none focus:ring-2 focus:ring-white/20 disabled:cursor-not-allowed disabled:opacity-70 disabled:hover:bg-zinc-200"
                disabled={Object.keys(errors).length > 0 || loading}
              >
                {loading ? (
                  <svg className="animate-spin h-5 w-5 text-zinc-950" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                ) : (
                  <span className="relative z-10 inline-flex h-full w-full items-center justify-center rounded-md bg-zinc-200 duration-150 hover:bg-zinc-300/90">Create Endpoint</span>
                )}
                <span className="absolute h-full w-full transform-gpu animate-fade-in blur-[1rem] opacity-0" style={{ background: 'linear-gradient(90deg, rgba(219, 0, 255, 0.4) 0%, rgba(97, 0, 255, 0.6) 35%, rgba(20, 0, 255, 0.6) 65%, rgba(0, 255, 255, 0.4) 100%)' }}></span>
              </button>
            </div>
          </form>
        </div>
        <button
          className="absolute right-4 top-4 inline-flex h-6 w-6 items-center justify-center rounded bg-black text-zinc-400 transition duration-200 ease-in-out hover:bg-zinc-800 hover:text-zinc-200 focus:outline-none focus:ring-1 focus:ring-zinc-600"
          aria-label="Close"
          type="button"
          onClick={onClose}
        >
          <svg className="h-5 w-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.25 6.75L6.75 17.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M6.75 6.75L17.25 17.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
          </svg>
        </button>
      </div>
    </>
  );
};

export default CreateEndpoint;