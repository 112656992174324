import React from "react";
import Footer from "components/footer/FooterAuthDefault";
import { Routes, Route, Navigate } from "react-router-dom"; // Add useNavigate
import routes from "routes.js";
// import React, { useEffect } from "react";
import axios from "axios";

axios.defaults.withCredentials = true;

// const API_URL = process.env.REACT_APP_API_URL;

export default function Auth() {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   // Redirigir al usuario a la página de proyectos después de la autenticación
  //   axios.get(`${API_URL}/auth/google/callback`)
  //     .then(response => {
  //       if (response.data.token) {
  //         localStorage.setItem('jwt', response.data.token);
  //         navigate('/projects');
  //       }
  //     })
  //     .catch(error => console.log(error));
  // }, [navigate]);

  // useEffect(() => {
  //   // Chequear si el usuario está autenticado y redirigir
  //   axios.get(`${API_URL}/auth/check`)
  //     .then(response => {
  //       if (response.data.authenticated) {
  //         navigate('/projects');
  //       }
  //     })
  //     .catch(error => console.log(error));
  // }, [navigate]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
    <div className="min-h-screen bg-black">
      <div className="relative w-full">
        <main className="min-h-screen">
          <div className="relative flex">
            <div className="mx-auto flex min-h-screen w-full flex-col pt-12 md:max-w-[75%] lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:max-w-[1383px] xl:px-0">
              <div className="mb-auto flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                <Routes>
                  {getRoutes(routes)}
                  <Route
                    path="/"
                    element={<Navigate to="/auth/sign-in" replace />}
                  />
                </Routes>
              </div>
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}



// import Footer from "components/footer/FooterAuthDefault";
// import { Routes, Route, Navigate } from "react-router-dom";
// import routes from "routes.js";
// import FixedPlugin from "components/fixedPlugin/FixedPlugin";

// export default function Auth() {
//   const getRoutes = (routes) => {
//     return routes.map((prop, key) => {
//       if (prop.layout === "/auth") {
//         return (
//           <Route path={`/${prop.path}`} element={prop.component} key={key} />
//         );
//       } else {
//         return null;
//       }
//     });
//   };
//   document.documentElement.dir = "ltr";
//   return (
//     <div>
//       <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
//         <FixedPlugin />
//         <main className={`mx-auto min-h-screen`}>
//           <div className="relative flex">
//             <div className="mx-auto flex min-h-full w-full flex-col pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
//               <div className="mb-auto flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
//                 <Routes>
//                   {getRoutes(routes)}
//                   <Route
//                     path="/"
//                     element={<Navigate to="/auth/sign-in" replace />}
//                   />
//                 </Routes>
//               </div>
//               <Footer />
//             </div>
//           </div>
//         </main>
//       </div>
//     </div>
//   );
// }
