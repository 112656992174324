import React from 'react';

const ConfirmModal = ({ isOpen, onClose, onConfirm, title, message }) => {
    if (!isOpen) return null;

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const handleBackgroundClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onClose(e);
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 cursor-default" onClick={handleBackgroundClick}>
            <div className="fixed inset-0 bg-black opacity-50"></div>
            <div className="bg-white rounded-[5px] shadow-lg p-6 z-10" onClick={handleClick}>
                <h2 className="text-xl font-semibold mb-4">{title}</h2>
                <p className="mb-4">{message}</p>
                <div className="flex justify-center space-x-4">
                    <button
                        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                        onClick={(e) => {
                            handleClick(e);
                            onConfirm(e);
                        }}
                    >
                        Confirm
                    </button>
                    <button
                        className="px-4 py-2 bg-black rounded hover:bg-zinc-800 text-white"
                        onClick={(e) => {
                            handleClick(e);
                            onClose(e);
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;