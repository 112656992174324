import React, { useState } from 'react';
import axios from 'axios';
import { FaTrash, FaEdit, FaClone } from 'react-icons/fa';
import { useNotification } from "components/Notification/NotificationContext";
import ConfirmModal from './ConfirmModal';
// import ModalViewDetails from './ModalViewDetails';

const API_URL = process.env.REACT_APP_API_URL;

const ProjectActions = ({ projectId, onEdit, onView, onDelete }) => {
    const { showNotification } = useNotification();
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const token = localStorage.getItem('jwt');
    // const [isViewModalOpen, setIsViewModalOpen] = useState(false);

    const handleView = (e) => {
        e.preventDefault();
        e.stopPropagation();
        // setIsViewModalOpen(true);
    };

    const handleEdit = async(e) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            await axios.get(`${API_URL}/project/update/${projectId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            onEdit(projectId);
            showNotification('Project updated successfully');
        } catch (error) {
            showNotification('Error updating project', 'error');
        }
    };

    const handleDelete = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsConfirmModalOpen(true);
    };

    const confirmDelete = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsConfirmModalOpen(false);
        try {
            await axios.delete(`${API_URL}/project/delete/${projectId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            onDelete(projectId);
            showNotification('Project deleted successfully');
        } catch (error) {
            showNotification('Error deleting project', 'error');
        }
    };

    return (
        <div className="flex items-center space-x-2">
            <button
                className="flex items-center justify-center w-8 h-8 rounded-full text-white"
                onClick={handleView}
                title='Clone project'
                disabled
            >
                <FaClone className="h-4 w-4" />
            </button>
            <button
                className="flex items-center justify-center w-8 h-8 rounded-full text-white"
                onClick={handleEdit}
                title='Edit project'
                disabled
            >
                <FaEdit className="h-4 w-4" />
            </button>
            <button
                className="flex items-center justify-center w-8 h-8 rounded-full text-white"
                onClick={handleDelete}
                title='Delete project'
            >
                <FaTrash className="h-4 w-4" />
            </button>

            <ConfirmModal
                isOpen={isConfirmModalOpen}
                onClose={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsConfirmModalOpen(false);
                }}
                onConfirm={confirmDelete}
                title="Confirm to delete"
                message="Are you sure you want to delete this project?"
            />
            {/* <ModalViewDetails
                isOpen={isViewModalOpen}
                onClose={() => setIsViewModalOpen(false)}
                projectId={projectId}
            /> */}

        </div>
    );
};

export default ProjectActions;