// Custom components
import React from "react";

function InputField(props) {
  const { label, id, extra, type, placeholder, variant, state, disabled, error, onChange, value } = props;

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`text-sm text-white ${
          variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
        }`}
      >
        {label}
      </label>
      <input
        disabled={disabled}
        type={type}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
          disabled === true
            ? "!border-none bg-white/5 placeholder:text-[rgba(255,255,255,0.35)]"
            : state === "error" || error
            ? "border-red-500 text-red-500 placeholder:text-red-500 border-red-400 text-red-400 placeholder:text-red-400"
            : state === "success"
            ? "border-green-500 text-green-500 placeholder:text-green-500 border-green-400 text-green-400 placeholder:text-green-400"
            : "border-[#333333] text-white"
        }`}
      />
      {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
    </div>
  );
}

export default InputField;
