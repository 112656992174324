import React, { useEffect, useState, useCallback } from "react";
import { useNotification } from "components/Notification/NotificationContext";
import Modal from "./components/Modal";
import axios from "axios";
import ProjectActions from "./components/ProjectActions";

axios.defaults.withCredentials = true;

const API_URL = process.env.REACT_APP_API_URL;

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { notification } = useNotification();
  const token = localStorage.getItem('jwt');

  const handleCreateProject = () => {
    setIsModalOpen(true);
  };

  const refreshProjects = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/projects`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setProjects(response.data);
    } catch (error) {
      console.error('Error fetching projects data:', error);
    } finally {
      setLoading(false);
    }
  }, [token]);

  const handleSubmit = async (projectData) => {
    try {
      await axios.post(`${API_URL}/project/create`, projectData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      refreshProjects();
    } catch (error) {
      console.error('Error creating project:', error);
    }
  };

  useEffect(() => {
    refreshProjects();
  }, [refreshProjects]);

  return (
    <div>
      <div className="mt-5 flex justify-between items-center relative z-10">
        <h2 className="text-xl font-bold text-zinc-200">Projects</h2>
        <button
          className="relative inline-flex shrink-0 font-medium text-zinc-200"
          onClick={handleCreateProject}
        >
          <span className="z-10 inline-flex h-full w-full items-center justify-center rounded-md bg-lightPrimary px-3 py-1.5 ring-1 ring-zinc-400/10 transition hover:ring-zinc-400/40 max-md:hidden">
            New project
          </span>
          <span
            className="z-10 inline-flex h-full w-full items-center justify-center rounded-md bg-lightPrimary px-2 py-1.5 ring-1 ring-zinc-400/10 transition hover:ring-zinc-400/40 md:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg" width="18" height="18"
              viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"
              className="lucide lucide-plus">
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
          </span>
          <span
            className="absolute h-full w-full blur-[1rem] motion-safe:animate-pulse"
            style={{ background: 'linear-gradient(90deg, rgba(255, 214, 0, 0.2) 0%, rgba(255, 46, 0, 0.3) 10%, rgba(219, 0, 255, 0.5) 25%, rgb(97, 0, 255) 45%, rgb(0, 255, 117) 65%, rgb(0, 255, 255) 80%, rgba(20, 0, 255, 0.3) 100%)' }}>
          </span>
        </button>
      </div>

      {/* Modal para crear un nuevo proyecto */}
      {notification && (
        <div className="bg-green-500 py-2 px-4 rounded-md text-white text-center fixed bottom-4 right-4 flex gap-4" role="alert">
          <span className="font-medium">{notification}</span>
        </div>
      )}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleSubmit}
        refreshProjects={refreshProjects}
      />

      {/* Renderizar Proyectos */}
      {loading ? (
        <div className="mt-5 flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white"></div>
        </div>
      ) : projects.length === 0 ? (
        <div className="mt-5 flex justify-center items-center">
          <p className="text-lg font-bold text-white">No projects yet</p>
        </div>
      ) : (
        <>
        <div className="mt-5 grid grid-cols-1 gap-3">
          {projects.map((project) => (
            <a
              key={project._id}
              href={`/projects/${project._id}`}
              className="!z-5 relative flex flex-col rounded-[5px] bg-clip-border border border-dashed border-[#333333] shadow-md text-center no-underline transition duration-300 p-[0.0625rem] hover:border-solid hover:border-[#333333]"
            >
              <div class="relative p-[1rem] bg-black rounded-[5px] overflow-hidden xl:p-[1rem]">
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    <div className="relative flex">
                      <div className="relative flex items-center justify-center h-10 w-10 rounded-full bg-white text-black">
                        <span className="text-lg font-bold">
                          {project.name.charAt(0).toUpperCase()}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="flex h-full w-full flex-row justify-between items-center sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
                    <div className="flex flex-col ml-5 items-center">
                      <p className="text-base font-bold text-white">{project.name}</p>
                    </div>
                    <div className="flex flex-row items-center gap-2 relative">
                      <ProjectActions
                        projectId={project._id}
                        onEdit={refreshProjects}
                        onDelete={refreshProjects}
                      />

                      {/* <button className="flex items-center justify-center w-8 h-8 rounded-full text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                        </svg>
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>
        </>
      )}
    </div>
  );
};

export default Projects;



// {/* <div className="mt-5 grid grid-cols-1 gap-5">
//   {projects.map((project) => (
//     <a
//       key={project.id}
//       href={`/projects/${project._id}`}
//       className="!z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none !p-[10px] text-center no-underline hover:bg-purple-100 dark:hover:bg-purple-500 hover:shadow-lg transition duration-200"
//     >
//       <div className="flex justify-between">
//         <div className="flex justify-between">
//           <div className="relative flex">
//             <div className="flex items-center justify-center">
//               <img className="h-14 w-14 rounded-full" src="/static/media/avatar4.54d5c1de851c273b2cd9.png" alt="Elon Musk"/>
//             </div>
//           </div>
//         </div>

//         <div className="flex h-full w-full flex-row justify-between items-center sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
//           <div className="flex flex-col ml-5 items-center">
//             <p className="text-3xl font-bold text-navy-700 dark:text-white">{project.name}</p>
//           </div>
//           <div className="flex flex-col items-center">
//             <button
//               className="flex items-center justify-center w-10 h-10 rounded-full bg-gradient-to-br from-purple-600 to-purple-400 hover:from-purple-700 hover:to-purple-500 text-white"
//             >
//               <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
//               </svg>
//             </button>
//           </div>
//         </div>
//       </div>
//     </a>
//   ))}
// </div> */}